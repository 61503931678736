import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {UserOtpStatusResourceService, UserOtpStatusResponse} from "@gtd/api-services/b2b-client";

@Component({
  selector: 'gtd-dialog-otp-enabled',
  templateUrl: './dialog-otp-enabled.component.html',
  styleUrls: ['./dialog-otp-enabled.component.scss']
})
export class DialogOtpEnabledComponent implements OnInit {

  isLoading: boolean;
  isSuccess: boolean;

  resultOtp: UserOtpStatusResponse;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogOtpEnabledComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    @Inject(PLATFORM_ID) private platformId: any,
    private userOtpStatusResourceService: UserOtpStatusResourceService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {

  }


  formGroup: FormGroup = new FormGroup({
    password: new FormControl([], Validators.required)
  })


  ngOnInit() {
    this.formGroup.controls['password'].valueChanges.subscribe(change => {
      this.isLoading = false;
    })
  }

  closePopup() {
    this.dialogRef.close({success: this.isSuccess});
  }

  submitEnable() {
    if(this.formGroup.valid) {
      this.isLoading = true;
      this.userOtpStatusResourceService.switchUserOtpStatusUsingPOST(
        {
          "isOtpEnabled": this.data,
          "password": this.formGroup.controls['password'].value
        }
      ).subscribe(otpRs => {
        this.isLoading = false;
        if(!otpRs.success) {
          this.isSuccess = false;
          if(otpRs.errors.length && otpRs.errors[0].code === '14_OTP_STATUS_BLOCK') {
            this.resultOtp = otpRs.result
          } else {
            const errorMessage = otpRs.errors.length? otpRs.errors[0].code : "An error occurred";
            this.formGroup.controls['password'].setErrors({ incorrect: errorMessage });
          }
        } else {
          this.isSuccess = true;
          this.snackBar.open(
            this.data? 'Mở xác thực OTP thành công': 'Hủy xác thực OTP thành công',
            'x',
            {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.dialogRef.close({success: this.isSuccess});
        }
      })
    }
  }
}

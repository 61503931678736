import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SnackbarComponent} from '@gtd/components/snackbar';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogMessageComponent} from '@gtd/components/dialog-message';
import {AuthenticateService} from '../authenticate/authenticate.service';
import {UserJwtControllerService} from '@gtd/api-services/b2b-client';
import {environment} from '@envB2b/environment';
import {fadeIn, fadeInOut} from '@gtd/helpers';
import {TemplateService} from "@gtd/services";
import {AccountFacade} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInOut, fadeIn]
})
export class LoginComponent implements OnInit {
  isLogged: boolean;
  hide = true;
  isLoading = false;
  loginForm!: FormGroup;
  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticateService,
    private userJwtControllerService: UserJwtControllerService,
    private activatedRoute: ActivatedRoute,
    private accountFacade: AccountFacade,
    private templateService: TemplateService
  ) {
    this.templateService.setTemplateGtd('agent-template');
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.action) {
        switch (param.action) {
          case 'force_logout':
            this.authenticationService.logoutObs().subscribe(result => {
              if (result) {
                window.open('auth/login', '_self');
              }
            });
            break;
        }
      }
    });
    this.createForm();
  }

  ngOnInit() {
    if (this.authenticationService.isAuthenticated() === true) {
      // const token = this.authenticationService.getToken();
      // if(this.authenticationService.isTokenExpired(token) === false) {
      //   setTimeout(() => {
      //     window.location.href = environment.b2bSite + '/#/login-token?token='+token;
      //   }, 100);
      // }
      this.router.navigate(['/']);
    }
  }

  submitForm() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.logging-in', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.userJwtControllerService
        .authorizeUsingPOST({
          username: this.loginForm.controls['username'].value,
          password: this.loginForm.controls['password'].value,
          rememberMe: this.loginForm.controls['rememberMe'].value
        })
        .subscribe(
          results => {
            this.isLoading = false;
            this._snackBar.dismiss();
            if (results && results['id_token']) {
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: { message: 'user.login-success', icon: 'check' },
                panelClass: 'snackbar-success',
                verticalPosition: 'top',
                duration: 2000
              });
              this.authenticationService.storeAuthenticationToken({
                username: this.loginForm.controls['username'].value,
                token: results['id_token'],
                remember: this.loginForm.controls['rememberMe'].value
              });
              this.accountFacade.getAccountUsingGet();
              this.router.navigate(['/']);
            } else {
              this.isLoading = false;
              this._snackBar.dismiss();
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: {
                  message: 'user.login-failed',
                  icon: 'exclamation-triangle'
                },
                panelClass: 'snackbar-error',
                verticalPosition: 'top',
                duration: 1500
              });
            }
          },
          errors => {
            this.isLoading = false;
            this._snackBar.dismiss();
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {
                message: 'user.login-failed',
                icon: 'exclamation-triangle'
              },
              panelClass: 'snackbar-error',
              verticalPosition: 'top',
              duration: 1500
            });
            if (
              errors &&
              errors.error &&
              errors.error.errors &&
              errors.error.errors.length > 0
            ) {
              switch (errors.error.errors[0].id) {
                case '4002':
                  this.loginForm.controls['username'].setErrors([
                    'not_registered'
                  ]);
                  break;
                case '4003':
                  this.loginForm.controls['password'].setErrors([
                    'password_invalid'
                  ]);
                  break;
                case '4005':
                  this.loginForm.controls['password'].setErrors([
                    'password_invalid'
                  ]);
                  break;
                case '4004':
                  this.loginForm.controls['username'].setErrors([
                    'not_activated'
                  ]);
                  break;
                default:
                  this.dialog.open(DialogMessageComponent, {
                    data: {
                      btn: [{ btnText: 'global.close' }],
                      content: 'user.system-error-message',
                      heading: 'user.system-error',
                      icon: 'exclamation-triangle',
                      messageType: 'error'
                    },
                    panelClass: 'dialog-message',
                    disableClose: true
                  });
                  break;
              }
            }
          }
        );
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.logging-in', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
    }
  }

  getErrorMessage() {
    return this.loginForm.controls['username'].hasError('required')
      ? 'user.email-phone-can-not-be-empty'
      : this.loginForm.controls['username'].hasError('email')
      ? 'user.email-phone-invalid'
      : this.loginForm.controls['username'].errors !== null &&
        this.loginForm.controls['username'].errors[0] === 'not_registered'
      ? 'user.email-phone-not-exist'
      : this.loginForm.controls['username'].errors !== null &&
        this.loginForm.controls['username'].errors[0] === 'not_activated'
      ? 'user.account-is-not-activated'
      : this.loginForm.controls['password'].hasError('required')
      ? 'user.password-not-empty'
      : this.loginForm.controls['password'].errors !== null &&
        this.loginForm.controls['password'].errors[0] === 'password_invalid'
      ? 'user.password-incorrect'
      : '';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: true
    });
  }
}

import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable, timer} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {map, take} from "rxjs/operators";
import {PaymentResourcesService} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-dialog-email-otp',
  templateUrl: './dialog-email-otp.component.html',
  styleUrls: ['./dialog-email-otp.component.scss']
})
export class DialogEmailOtpComponent implements OnInit {
  @ViewChild('otpInputRef', { static: false }) otpInputRef: any;

  otp: string;
  counter$: Observable<number>;
  counter: number;
  count = 180;
  isLoading: boolean;
  isLoadingResend: boolean;
  isValid: boolean;
  messageError: string;

  constructor(
    public dialogRef: MatDialogRef<DialogEmailOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentResourcesService: PaymentResourcesService,
    private snackBar: MatSnackBar
  ) {
    this.counter$ = timer(0,1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  ngOnInit() {
    this.counter$.subscribe(counter => {
      this.counter = counter;
    })
  }

  onOtpChange(otp: string) {
    this.isValid = otp.toString().length === 6;
    this.otp = otp;
    this.messageError = null
  }

  onNoClick(type?: string) {
    this.paymentResourcesService
      .paymentBookingV2UsingPOST({
        ...this.data.mappingPaymentBookingReq,
        userOtpRequest: {
          otpCode: '000000'
        }
      })
      .subscribe();
    this.dialogRef.close({action: type});
  }

  onActionClick(): void {
    this.isLoading = true;
    this.messageError = null;
    this.paymentResourcesService
      .paymentBookingV2UsingPOST({
        ...this.data.mappingPaymentBookingReq,
        userOtpRequest: {
          otpCode: this.otp
        }
      })
      .subscribe(
        value => {
          this.isLoading = false;
          console.log(value)
          if (value.success) {
            this.dialogRef.close({action: 'CONFIRM'});
          } else {
            this.messageError = value.errors[0].code
          }
        }
      );
  }
  onActionResend(): void {
    if(!this.isLoadingResend && !this.isLoading) {
      this.isLoadingResend = true;
      this.paymentResourcesService
        .paymentBookingV2UsingPOST({
          ...this.data.mappingPaymentBookingReq,
          userOtpRequest: {
            sendingOtp: true
          }
        })
        .subscribe(
          value => {
            this.isLoadingResend = false;
            if (!value.success && value.userOtpResponse && value.userOtpResponse.otpSent) {
              this.otp = null;
              this.otpInputRef.setValue(null);
              this.snackBar.open(
                'Gửi lại OTP thành công',
                'x',
                {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
            } else {
              this.messageError = value.errors.length && value.errors[0].code? value.errors[0].code: null
              this.snackBar.open(
                'Gửi lại OTP thất bại',
                'x',
                {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
            }
          }
        );
    }
  }
}

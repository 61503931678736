export * from './agencyResource.service';
import { AgencyResourceService } from './agencyResource.service';
export * from './agentAvatarResource.service';
import { AgentAvatarResourceService } from './agentAvatarResource.service';
export * from './agentCustomerMemberCardResource.service';
import { AgentCustomerMemberCardResourceService } from './agentCustomerMemberCardResource.service';
export * from './agentCustomerResource.service';
import { AgentCustomerResourceService } from './agentCustomerResource.service';
export * from './agentProfileResource.service';
import { AgentProfileResourceService } from './agentProfileResource.service';
export * from './agentScheduleResource.service';
import { AgentScheduleResourceService } from './agentScheduleResource.service';
export * from './branchResource.service';
import { BranchResourceService } from './branchResource.service';
export * from './careerResource.service';
import { CareerResourceService } from './careerResource.service';
export * from './clientMappingResource.service';
import { ClientMappingResourceService } from './clientMappingResource.service';
export * from './lableResource.service';
import { LableResourceService } from './lableResource.service';
export * from './linkedAccountResource.service';
import { LinkedAccountResourceService } from './linkedAccountResource.service';
export * from './migrateDataResource.service';
import { MigrateDataResourceService } from './migrateDataResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './reconciliationGeneralMappingResource.service';
import { ReconciliationGeneralMappingResourceService } from './reconciliationGeneralMappingResource.service';
export * from './reconciliationGeneralTypeResource.service';
import { ReconciliationGeneralTypeResourceService } from './reconciliationGeneralTypeResource.service';
export * from './referralResource.service';
import { ReferralResourceService } from './referralResource.service';
export const APIS = [
  AgencyResourceService,
  AgentAvatarResourceService,
  AgentCustomerMemberCardResourceService,
  AgentCustomerResourceService,
  AgentProfileResourceService,
  AgentScheduleResourceService,
  BranchResourceService,
  CareerResourceService,
  ClientMappingResourceService,
  LableResourceService,
  LinkedAccountResourceService,
  MigrateDataResourceService,
  ProfileInfoResourceService,
  ReconciliationGeneralMappingResourceService,
  ReconciliationGeneralTypeResourceService,
  ReferralResourceService,
];

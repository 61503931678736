import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShortProfileFacade} from "@gtd/api-services/customer-service";
import {ProfileFacade} from "./+state/profile.facade";
import {Subscription} from "rxjs";
import {CountryFacade} from "@gtd/meta";
import {ActivatedRoute} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {
  DialogOtpEnabledComponent
} from "@gtd/components/dialog-message";
import {MatDialog} from "@angular/material";
import {UserOtpStatusResourceService} from "@gtd/api-services/b2b-client";
import {CredentialsService as CredentialsServiceB2B} from "@gtd/auth-b2b";

@Component({
  selector: 'gtd-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  groupPannel = 0;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');
  groupPanel = 0;

  formSecurityOtp: FormGroup = new FormGroup({
    'enabled': new FormControl(false),
  })

  enableApproval = this.credentialsServiceB2B.enableApprovalCheckout;

  constructor(
    private shortProfileFacade: ShortProfileFacade,
    private profileFacade: ProfileFacade,
    private activatedRoute: ActivatedRoute,
    private countryFacade: CountryFacade,
    public dialog: MatDialog,
    private userOtpStatusResourceService: UserOtpStatusResourceService,
    private credentialsServiceB2B: CredentialsServiceB2B,
  ) {
    this.activatedRoute.queryParams.subscribe(value => {
      if(value && value.groupPannel) {
        this.groupPannel = parseInt(value.groupPannel)
      }
    })
  }

  setGroup(index: number) {
    this.groupPannel = index;
  }
  ngOnInit() {
    this.userOtpStatusResourceService.getUserOtpStatusUsingGET().subscribe(
      otpStatus => {
        if(otpStatus.success) {
          this.formSecurityOtp.controls['enabled'].setValue(otpStatus.result.otpEnabled);
        }
      }
    )
    this.countryFacade.loadAll({
      page: 0,
      size: 1000,
      sort: ['sortname,asc']
    });
    this.subscriptions.add(
      this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
        if (shortProfile && shortProfile.profileId) {
          this.profileFacade.getProfileUsingGet(shortProfile.profileId);
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  switchOtp() {
    setTimeout(() => {
      const dialogRefOtp = this.dialog.open(DialogOtpEnabledComponent, {
        data: !this.formSecurityOtp.controls['enabled'].value,
        disableClose: true,
        panelClass: 'dialog-message',
        width: '594px',
        position: {
          top: '150px'
        }
      });
      dialogRefOtp.afterClosed().subscribe(dialogRs => {
        if(dialogRs && dialogRs.success) {
          this.formSecurityOtp.controls['enabled'].setValue(!this.formSecurityOtp.controls['enabled'].value);
        }
      })
    }, 100)
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UserComponent } from './user.component';
import { UserModuleRouting } from './user.module.routing';
import { ProfileComponent } from './profile/profile.component';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faCalendarAlt,
  faCheckCircle,
  faCreditCard,
  faEdit,
  faHandshake,
  faPlusSquare,
  faTimesCircle,
  faUser
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatTabsModule,
  MatToolbarModule,
} from '@angular/material';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  faAlignLeft,
  faAngleDoubleDown,
  faAngleRight,
  faArrowLeft,
  faBed,
  faChevronRight,
  faCog,
  faEllipsisV,
  faFileAlt,
  faHotel,
  faMarsStrokeV,
  faPlane,
  faSearch,
  faTrash,
  faUserPlus,
  faUsers,
  faVenus,
  faReceipt,
  faSync,
  faSortAmountDownAlt,
  faClone,
  faChevronLeft,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { ProfileUpdateComponent } from './profile/profile-update/profile-update.component';
import { PaperInformationComponent } from './profile/paper-information/paper-information.component';
import { BillingInformationComponent } from './profile/billing-information/billing-information.component';
import { PassengersComponent } from './passengers/passengers.component';
import { EditPassengerComponent } from './passengers/edit-passenger/edit-passenger.component';
import { FormPassengerComponent } from './passengers/form-passenger/form-passenger.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerTravellerModule } from './customer-traveller/customer-traveller.module';
import { UpdateAvatarComponent } from './profile/profile-update/update-avatar/update-avatar.component';
import { LibraryAvatarModule } from './profile/profile-update/update-avatar/library-avatar/library-avatar.module';
import { AvatarModule } from './profile/avatar/avatar.module';
import { ProfileModule } from './profile/profile.module';
import { PassengerService } from './passengers/passenger.service';
import { MemberCardComponent } from './passengers/member-card/member-card.component';
import { CountryModule, LookupModule } from '@gtd/meta';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MemberCardUpdateComponent } from './profile/member-card-update/member-card-update.component';
import { TimeCountDownBetweenModule } from '@gtd/directives/time-count-down-between';
import {
  BookingsComponent,
  ServiceRequests,
  TotalFare,
  TotalPriceItemDetail,
  TotalServiceFee
} from './bookings/bookings.component';
import { FilterModule } from './bookings/actions/filter/filter.module';
import { BookingItemComponent } from './bookings/booking-item/booking-item.component';
import { DatePipeModule } from '@gtd/components/date-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { BookingDetailComponent } from './bookings/booking-detail/booking-detail.component';
import { DetailStateModule } from './bookings/booking-detail/detail-state/detail-state.module';
import {
  FlightDetailComponent,
  PassengerNameRecord
} from './bookings/flight-detail/flight-detail.component';
import { AirlinesModule } from '@gtd/components/airlines';
import {
  FlightBookingInfoComponent,
  GetPassengerNameRecord
} from './bookings/booking-detail/flight/flight-booking-info/flight-booking-info.component';
import { FlightPanelPriceComponent } from './bookings/booking-detail/flight/flight-panel-price/flight-panel-price.component';
import { HotelPanelPriceComponent } from './bookings/booking-detail/hotel/hotel-panel-price/hotel-panel-price.component';
import {
  DateParse,
  DialogPanelComponent,
  TotalPriceItemRoom
} from './bookings/booking-detail/hotel/hotel-panel-price/dialog-panel/dialog-panel.component';
import { HotelBookingInfoComponent } from './bookings/booking-detail/hotel/hotel-booking-info/hotel-booking-info.component';
import { HotelPassengerComponent } from './bookings/booking-detail/hotel/hotel-passenger/hotel-passenger.component';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import {
  InsurancePolicyByBookingModule,
  StateBookingResultModule
} from '@gtd/booking-result';
import {
  getDutchPaginatorIntl,
  NotificationsComponent
} from './notifications/notifications.component';
import { StateNotificationModule } from './notifications/state-notification/state-notification.module';
import { SettingsComponent } from './settings/settings.component';
import { ChangePasswordProfileComponent } from './profile/change-password-profile/change-password-profile.component';
import {
  BasePartnerResourceService,
  AdditionalRequestResourceService
} from '@gtd/b2c-client';
import { BreadcrumbBarModule } from '@gtd/components/breadcrumb-bar';
import { DragScrollModule } from 'ngx-drag-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UpperCaseInputModule } from '@gtd/directives/upper-case-input';
import { LoadingModule } from '@gtd/components/loading';
import { ComboBookingItemComponent } from './bookings/combo-booking-item/combo-booking-item.component';
import {
  ComboBookingInfoComponent,
  GetComboPassengerNameRecord
} from './bookings/booking-detail/combo/combo-booking-info/combo-booking-info.component';
import { HotelDetailComponent } from './bookings/hotel-detail/hotel-detail.component';
import { ComboPanelPriceComponent } from './bookings/booking-detail/combo/combo-panel-price/combo-panel-price.component';
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component';
import { MatTableModule } from '@angular/material/table';
import { DatepickerModule } from '@gtd/components/datepicker';
import { UtilitysrvModule } from '@gtd/api-services/utilitysrv';
import {
  AccountResourceService,
  B2bClientApiModule, B2bClientModule,
  CustomerResourceService,
  UserProfileResourceService
} from '@gtd/api-services/b2b-client';
import { TransactionFinancialComponent } from './transaction-financial/transaction-financial.component';
import { FilterFinancialComponent } from './transaction-financial/filter-financial/filter-financial.component';
import { LoadingCardComponent } from './loading-card/loading-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BookingServiceModule } from '@gtd/api-services/booking-service';
import { ReferralsComponent } from './referrals/referrals.component';
import { ReferralPolicyComponent } from './referrals/referral-policy/referral-policy.component';
import { ReferralInfoComponent } from './referrals/referral-info/referral-info.component';
import {
  ReferralCommissionsComponent,
  TotalCommission
} from './referrals/referral-commissions/referral-commissions.component';
import { EinvoicedBusinessInformationComponent } from './profile//einvoiced-business-information/einvoiced-business-information.component';
import { FlightBookingOfflineInfoComponent } from './bookings/booking-detail/flight/flight-booking-offline-info/flight-booking-offline-info.component';
import { TourBookingItemModule } from '@gtd/extra/tour/ui/tour-booking-item';
import { TourBookingDetailModule } from '@gtd/extra/tour/ui/tour-booking-detail';
import { TourStoreModule } from '@gtd/extra/tour/data-access';
import { TourPriceDetailModule } from '@gtd/extra/tour/ui/tour-price-detail';
import { TourTravellerInformationModule } from '@gtd/extra/tour/ui/tour-traveller-information';
import { MatRadioModule } from '@angular/material';
import { MemberCardB2bComponent } from './passengers/member-card-b2b/member-card-b2b.component';
import {
  AgentAccountTransactionResourceService,
  BASE_PATH
} from '@gtd/api-services/pricingsrv';
import { environment } from '@env/environment';
import { PersonnelComponent } from './personnel/personnel.component';
import { CustomerServiceModule } from '@gtd/api-services/customer-service';
import { ProfileUpdateB2bComponent } from './profile/profile-update/profile-update-b2b/profile-update-b2b.component';
import { CreateAdditionalRequestDialogComponent } from './bookings/create-additional-request-dialog/create-additional-request-dialog.component';
import { AdditionalRequestManagementComponent } from './additional-request-management/additional-request-management.component';
import { AdditionalRequestDetailComponent } from './additional-request-detail/additional-request-detail.component';
import { AdditionalRequestHistoryComponent } from './bookings/booking-detail/additional-request-history/additional-request-history.component';
import { CancelAdditionalRequestDialogComponent } from './cancel-additional-request-dialog/cancel-additional-request-dialog.component';
import {AgentCustomerMemberCardResourceService} from "@gtd/api-services/agentsrv";
import {MemberCardUpdateB2bComponent} from "./profile/member-card-update-b2b/member-card-update-b2b.component";
import {TcbFxTransactionModule} from "@gtd/components/tcb-fx-transaction";
import {SanitizeModule} from "@gtd/pipes/sanitize";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

library.add(
  faUser,
  faUsers,
  faAlignLeft,
  faBell,
  faCog,
  faEdit,
  faUserPlus,
  faPlusSquare,
  faPlane,
  faHotel,
  faChevronRight,
  faSearch,
  faArrowLeft,
  faCheckCircle,
  faTimesCircle,
  faAngleDoubleDown,
  faBed,
  faBell,
  faEllipsisV,
  faFileAlt,
  faMarsStrokeV,
  faTrash,
  faAngleRight,
  faVenus,
  faReceipt,
  faCreditCard,
  faSync,
  faSortAmountDownAlt,
  faCalendarAlt,
  faHandshake,
  faClone,
  faChevronLeft,
  faDownload
);

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        UserModuleRouting,
        MatIconModule,
        FontAwesomeModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatDatepickerModule,
        MatSelectModule,
        ReactiveFormsModule,
        CustomerTravellerModule,
        MatRippleModule,
        LibraryAvatarModule,
        AvatarModule,
        ProfileModule,
        LookupModule,
        CountryModule,
        NgxMatSelectSearchModule,
        MatTabsModule,
        FilterModule,
        MatCardModule,
        DatePipeModule,
        TranslateModule,
        MatToolbarModule,
        DetailStateModule,
        AirlinesModule,
        DialogMessageModule,
        StateBookingResultModule,
        MatCheckboxModule,
        MatMenuModule,
        MatBadgeModule,
        StateNotificationModule,
        MatProgressBarModule,
        MatPaginatorModule,
        InsurancePolicyByBookingModule,
        BreadcrumbBarModule,
        InsurancePolicyByBookingModule,
        InfiniteScrollModule,
        MatAutocompleteModule,
        DragScrollModule,
        UpperCaseInputModule,
        LoadingModule,
        MatTableModule,
        DatepickerModule,
        TimeCountDownBetweenModule,
        UtilitysrvModule,
        NgxPaginationModule,
        BookingServiceModule,
        B2bClientApiModule,
        TourBookingItemModule,
        TourBookingDetailModule,
        TourPriceDetailModule,
        TourTravellerInformationModule,
        TourStoreModule,
        MatRadioModule,
        CustomerServiceModule,
        TcbFxTransactionModule,
        SanitizeModule,
      MatSlideToggleModule,
      B2bClientModule
    ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.pricingsrv },
    PassengerService,
    BasePartnerResourceService,
    CustomerResourceService,
    AgentCustomerMemberCardResourceService,
    UserProfileResourceService,
    AccountResourceService,
    AdditionalRequestResourceService,
    AgentAccountTransactionResourceService,
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    DatePipe
  ],
  declarations: [
    UserComponent,
    ProfileComponent,
    SidebarComponent,
    ProfileUpdateComponent,
    PaperInformationComponent,
    BillingInformationComponent,
    EinvoicedBusinessInformationComponent,
    PassengersComponent,
    EditPassengerComponent,
    FormPassengerComponent,
    UpdateAvatarComponent,
    MemberCardComponent,
    MemberCardUpdateComponent,
    MemberCardUpdateB2bComponent,
    BookingsComponent,
    BookingItemComponent,
    BookingDetailComponent,
    FlightDetailComponent,
    FlightBookingInfoComponent,
    FlightPanelPriceComponent,
    HotelPanelPriceComponent,
    DialogPanelComponent,
    HotelBookingInfoComponent,
    HotelPassengerComponent,
    NotificationsComponent,
    SettingsComponent,
    TotalPriceItemRoom,
    DateParse,
    ChangePasswordProfileComponent,
    ServiceRequests,
    TotalPriceItemDetail,
    TotalServiceFee,
    TotalFare,
    GetPassengerNameRecord,
    GetComboPassengerNameRecord,
    PassengerNameRecord,
    ComboBookingItemComponent,
    ComboBookingInfoComponent,
    HotelDetailComponent,
    ComboPanelPriceComponent,
    InvoiceHistoryComponent,
    TransactionFinancialComponent,
    FilterFinancialComponent,
    LoadingCardComponent,
    ReferralsComponent,
    ReferralPolicyComponent,
    ReferralInfoComponent,
    ReferralCommissionsComponent,
    TotalCommission,
    FlightBookingOfflineInfoComponent,
    MemberCardB2bComponent,
    PersonnelComponent,
    ProfileUpdateB2bComponent,
    CreateAdditionalRequestDialogComponent,
    AdditionalRequestManagementComponent,
    AdditionalRequestDetailComponent,
    AdditionalRequestHistoryComponent,
    CancelAdditionalRequestDialogComponent
  ],
  exports: [FormPassengerComponent, SidebarComponent],
  bootstrap: [UserComponent],
  entryComponents: [
    CancelAdditionalRequestDialogComponent,
    CreateAdditionalRequestDialogComponent,
    AdditionalRequestDetailComponent
  ]
})
export class UserModule {}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ComboSearchBestRatesFacade} from '../../../../state/search-best-rates/search-best-rates.facade';
import {FilterAvailableState} from '../../../../state/filter-available/filter-available.reducer';
import {FilterAvailableFacade} from '../../../../state/filter-available/filter-available.facade';
import {TranslateService} from '@ngx-translate/core';
import {DialogMessageComponent} from '@gtd/components/dialog-message';
import {MatBottomSheet, MatDialog} from '@angular/material';
import {FilterOptionsFacade} from '../../../../state/filter-options/filter-options.facade';
import {DEFAULT_PAGINATION_OFFSET, DEFAULT_SORT_COMBO, encryptSearchAPI, fadeIn, fadeInOut} from '@gtd/helpers';
import {FilterPanelMobileComponent} from './filter-panel-mobile/filter-panel-mobile.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BreadcrumbBar} from '@gtd/components/breadcrumb-bar';
import {environment} from '@env/environment';
import * as moment from 'moment';
import {
  ComboVJSearchInformationFacade
} from '../../../../state/combo-search-information/combo-search-information.facade';
import {FlightSearchFacade} from '../../flight/flight-search/flight-search/+state/flight-search.facade';
import {AvailableFlightsFacade} from '../../flight/flight-search/flight-cache-search/+state/available-flights.facade';
import {
  LowestPriceFlightsFacade
} from '../../flight/flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import {FlightFilterFacade} from '../../flight/flight-search/flight-filter/+state/flight-filter.facade';
import {FlightFilterState} from '../../flight/flight-search/flight-filter/+state/flight-filter.reducer';
import {SelectItemSrvService} from '../../flight/select-item-srv.service';
import { AirItineraryInfo, GroupPricedItinerary, HotelResourceV3Service, SearchBestRatesResult } from '@gtd/b2c-client';
import {SubscriptionService} from '../../flight/flight-search/flight-search/+state/subscription.service';
import { delay, take, tap } from 'rxjs/operators';
import {GaTrackingService} from '@gtd/ga-tracking';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-hotel-search-result',
  templateUrl: './hotel-search-result.component.html',
  styleUrls: ['./hotel-search-result.component.scss'],
  animations: [fadeInOut, fadeIn]
})
export class HotelSearchResultComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() pageChange: EventEmitter<number>;
  configPagination: any;
  p: number = 1;

  counter = Array;
  searchParams: any;
  allSearchLoaded$ = this.searchBestRatesFacade.loaded$;
  allSearchBestRates$ = this.searchBestRatesFacade.allSearchBestRates$;
  isLoading: boolean;
  currentLang: 'vi' | 'en';
  selectedIndex: number = 1;
  currentFilter: FilterAvailableState;
  appName = environment.appName;
  currentFilter$ = this.flightFilterFacade.currentFilter$;
  allAvailableFlights$ = this.availableFlightsFacade.allAvailableFlights$;
  loaded$ = this.availableFlightsFacade.loaded$;
  loadedFlightSearch$ = this.flightSearchFacade.loaded$;
  allFlightSearch$ = this.flightSearchFacade.allFlightSearch$;
  getErrorFlightSearch$ = this.flightSearchFacade.getErrorFlightSearch$;
  flightAutoSelected$ = this._comboSelectedService.flightListSelected$;
  flightSelectedDone$ = this._comboSelectedService.flightSelectedDone$;
  totalFlightPrice$ = this._comboSelectedService.totalPrice$;
  airlineFilter$ = this._comboSelectedService.airlineOptions$;
  flightSearchParams: any;
  airlineFilter: string;
  flightSelectedDone = false;
  flightSelectedByUser = false;
  flightNotFound = false;
  adultTicketPrice = 0;
  params: string;
  private subscription: Subscription = new Subscription();
  utm_source = localStorage.getItem('utm_source');
  hotelRecommendedRates: any;
  @Input() isFilter: boolean;

  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'global.hotel'
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private _bottomSheet: MatBottomSheet,
    private filterOptionsFacade: FilterOptionsFacade,
    private comboSearchInformationFacade: ComboVJSearchInformationFacade,
    private flightSearchFacade: FlightSearchFacade,
    private filterAvailableFacade: FilterAvailableFacade,
    private searchBestRatesFacade: ComboSearchBestRatesFacade,
    private availableFlightsFacade: AvailableFlightsFacade,
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade,
    private selectItemSrvService: SelectItemSrvService,
    private _comboSelectedService: ComboSelectedService,
    private subService: SubscriptionService,
    private _gaTrackingService: GaTrackingService,
    private hotelResourceV3Service: HotelResourceV3Service,
    private cdr: ChangeDetectorRef
  ) {
    type OwnerNameLang = 'vi' | 'en';
    this.currentLang = this.translate.currentLang as OwnerNameLang;
    this.activatedRoute.queryParams.subscribe(param => {
      this.flightSelectedByUser = param.flightSelected;
      this.params = param.params;
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
    });
    setTimeout(() => {
      this.filterOptionsFacade.loadAll(this.currentLang);
    }, 1000);
    setTimeout(() => {
      this.subscription.add(
        this.translate.onLangChange.subscribe(lang => {
          this.filterAvailableFacade.changeFilterLanguage(lang.lang);
        })
      );
    });
  }
  private _handleQueryFromParams(param) {
    this.flightNotFound = false;
    this.searchParams = param;
    // Handle for flight
    this.flightSearchParams = {
      adutsQtt: param.adult,
      cabinClass: 'E',
      childrenQtt: param.child,
      departureDate: param.fromDate,
      destinationCode: param.to,
      infantsQtt: param.infant,
      originCode: param.from,
      combo: true,
      page: 0,
      returntureDate: param.toDate ? param.toDate : '',
      routeType: param.roundType,
      size: DEFAULT_PAGINATION_OFFSET,
      time: new Date().getTime().toString()
    };
    this.flightSearchParams['key'] = encryptSearchAPI(this.flightSearchParams);
    setTimeout(() => {
      if (!this.flightSelectedDone && !this._comboSelectedService.flightReset) {
        this.flightSearchFacade.loadAll(this.flightSearchParams);
      }
    }, 1000);
    // Handle for hotel
    const roomsArrs = JSON.parse(param.rooms);
    const roomsString = [];
    let hotelChildAgesArr = [];
    if (roomsArrs.length > 0) {
      roomsArrs.forEach(roomsArr => {
        let childrendString = '';
        hotelChildAgesArr = roomsArr.childrenAges.concat(roomsArr.infantAges);
        if (hotelChildAgesArr.length) {
          hotelChildAgesArr.forEach(childrend => {
            childrendString = childrendString
              ? childrendString + ',' + '' + childrend
              : '' + childrend;
          });
        }
        const room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(roomsArrs);
    }
    this.searchParams.roomsString = roomsString;
    this.searchParams.roomsArrs = roomsArrs;

    setTimeout(() => {
      if(param.location.searchType !== 'HOTEL') {
        this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
          'sales-env',
          'HOTEL_PACKAGE'
        );
        this.hotelResourceV3Service
          .searchRecommendedRatesUsingGET(
            param.location.searchCode,
            param.location.searchType,
            this.currentLang,
            'VND',
            moment(param.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
            moment(param.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
            this.searchParams.roomsString,
            null,
            param.location.supplier,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            0,
            3,
            this.searchParams.locationName
          )
          .subscribe(hotelRecommendedRates => {
            this.hotelRecommendedRates = hotelRecommendedRates;
          }, error => this.hotelRecommendedRates = []);
      }

      this.isLoading = true;
      this.searchBestRatesFacade.loadAll({
        searchCode: param.location.searchCode,
        searchType: param.location.searchType,
        supplier: param.location.supplier,
        language: this.currentLang || 'vi',
        currency: 'VND',
        checkIn: moment(param.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        checkOut: moment(param.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        paxInfos: roomsString,
        sortField: 'order',
        sortOrder: 'DESC',
        pageNumber: 0,
        pageSize: DEFAULT_PAGINATION_OFFSET
      });
    }, 1000);
  }

  ngOnInit() {
    this.dialog.closeAll();
    this.subscription.add(
      this.allFlightSearch$.subscribe(flightSearch => {
        if (
          (Object.keys(flightSearch).length &&
            !flightSearch.groupPricedItineraries) ||
          (Object.keys(flightSearch).length &&
            flightSearch.groupPricedItineraries &&
            !flightSearch.groupPricedItineraries.length)
        ) {
          // this.researchWhenReturnEmpty();
          this.flightNotFound = true;
        }
      })
    );
    this.subscription.add(
      this.flightSelectedDone$.pipe(take(1)).subscribe(done => {
        if (!done && this._comboSelectedService.flightReset) {
          this._comboSelectedService.resetFLight();
          this._comboSelectedService.flightReset = false;
          const flightBookingFromStorage = JSON.parse(
            localStorage.getItem('flightBooking')
          );
          for (const item of flightBookingFromStorage) {
            this._comboSelectedService.selectFlight(
              item.searchId,
              item.pricedItinerary,
              item.itemFlight,
              item.type
            );
          }
        }
      })
    );
    this.subscription.add(
      this.searchBestRatesFacade.allSearchBestRates$.subscribe(
        (allSearchBestRates: SearchBestRatesResult) => {
          if(allSearchBestRates) {
            if(allSearchBestRates.searchId) {
              this.isLoading = false;
            } else {
              let modalMs = this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
              modalMs.afterClosed().subscribe(() => this.isLoading = false)
            }
          }
        }
      )
    );
    this.subscription.add(
      this.filterAvailableFacade.currentFilter$.subscribe(
        (currentFilter: FilterAvailableState) => {
          if (currentFilter && Object.keys(currentFilter).length) {
            const filterKeys = Object.keys(currentFilter).filter(key => key !== 'loadMore' && key !== 'pageNumber');
            this.isFilter = filterKeys.length >= 1;

            this.currentFilter = currentFilter;

            this._bottomSheet.dismiss();
            setTimeout(() => {
              if(!currentFilter.loadMore) {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }
              this.searchBestRatesFacade.loadAll({
                searchCode: this.searchParams.location.searchCode,
                searchType: this.searchParams.location.searchType,
                supplier: this.searchParams.location.supplier,
                language: currentFilter.language
                  ? currentFilter.language
                  : this.currentLang,
                currency: 'VND',
                checkIn: moment(
                  this.searchParams.fromDate,
                  'MM-DD-YYYY'
                ).format('YYYY-MM-DD'),
                checkOut: moment(this.searchParams.toDate, 'MM-DD-YYYY').format(
                  'YYYY-MM-DD'
                ),
                paxInfos: this.searchParams.roomsString,
                filterHotelName: currentFilter.filterHotelName,
                filterHotelCategories: currentFilter.filterHotelCategories,
                filterFromPrice: currentFilter.filterPrice
                  ? currentFilter.filterPrice.fromPrice
                  : null,
                filterToPrice: currentFilter.filterPrice
                  ? currentFilter.filterPrice.toPrice
                  : null,
                filterStarRating: currentFilter.filterStarRating,
                filterFromGuestRating: currentFilter.filterGuestRating
                  ? currentFilter.filterGuestRating.from
                  : null,
                filterToGuestRating: currentFilter.filterGuestRating
                  ? currentFilter.filterGuestRating.to
                  : null,
                filterPropertyAmenities: currentFilter.filterPropertyAmenities,
                filterRoomAmenities: currentFilter.filterRoomAmenities,
                filterRoomViews: currentFilter.filterRoomViews,
                filterThemes: currentFilter.filterThemes,
                filterMealPlans: currentFilter.filterMealPlans,
                sortField: currentFilter.sort
                  ? currentFilter.sort.sortField
                  : 'order',
                sortOrder: currentFilter.sort
                  ? currentFilter.sort.sortOrder
                  : 'DESC',
                pageNumber: currentFilter.pageNumber
                  ? currentFilter.pageNumber
                  : 0,
                pageSize: currentFilter.pageSize ? currentFilter.pageSize : DEFAULT_PAGINATION_OFFSET
              }, currentFilter.loadMore);
            });
            this.searchParams.filterHotelName = currentFilter.filterHotelName;
            this.searchParams.filterHotelCategories =
              currentFilter.filterHotelCategories;
            this.searchParams.filterPrice = currentFilter.filterPrice;
            this.searchParams.filterStarRating = currentFilter.filterStarRating;
            this.searchParams.filterGuestRating =
              currentFilter.filterGuestRating;
            this.searchParams.filterRoomAmenities =
              currentFilter.filterRoomAmenities;
            this.searchParams.filterPropertyAmenities =
              currentFilter.filterPropertyAmenities;
            this.searchParams.filterRoomViews = currentFilter.filterRoomViews;
            this.searchParams.filterThemes = currentFilter.filterThemes;
            this.searchParams.filterMealPlans = currentFilter.filterMealPlans;
            this.searchParams.sort = currentFilter.sort;
            this.searchParams.pageNumber = currentFilter.pageNumber;
            this.searchParams.pageSize = currentFilter.pageSize;
          }
        }
      )
    );
    // if (!this.searchParams.flightSelected) {
    // }

    this.subscription.add(
      this.airlineFilter$.subscribe(airline => {
        if (airline) {
          this.airlineFilter = airline;
        }
      })
    );

    this.subscription.add(
      this.allAvailableFlights$.subscribe(availableFlights => {
        const groupPricedItineraries = availableFlights.groupPricedItineraries;
        if (
          availableFlights &&
          Object.keys(availableFlights).keys &&
          groupPricedItineraries &&
          groupPricedItineraries[0] &&
          !this.flightSelectedDone
        ) {
          this._comboSelectedService.selectFlight(
            availableFlights.searchId,
            groupPricedItineraries[0].pricedItineraries[0],
            groupPricedItineraries[0],
            this.searchParams.roundType
          );
        } else if (
          availableFlights &&
          Object.keys(availableFlights).keys &&
          groupPricedItineraries &&
          !groupPricedItineraries.length &&
          !this.flightSelectedDone
        ) {
          this._gaTrackingService.track('C_SearchResult_Empty');
          this.flightNotFound = true;
        }
      })
    );
    this.subscription.add(
      this.flightAutoSelected$.subscribe(listSelected => {
        if (
          listSelected &&
          listSelected[0] &&
          listSelected.length === 1 &&
          this.searchParams.roundType === 'ROUNDTRIP' &&
          !this._comboSelectedService.flightReset
        ) {
          //TODO recheck FlightTypeEnum.INTERNATIONAL
          if (
            listSelected[0].itemFlight.flightType ===
            GroupPricedItinerary.FlightTypeEnum.DOMESTIC
          ) {
            this._listenLowFareSearch2({
              groupId: listSelected[0].itemFlight.groupId,
              airlineCode: listSelected[0].itemFlight.airline,
              fareSourceCode:
                listSelected[0].pricedItinerary.airItineraryPricingInfo
                  .fareSourceCode,
              supplierCode: listSelected[0].itemFlight.airSupplier,
              searchId: listSelected[0].searchId
            });
          } else {
            this._listenLowFareSearchInter({
              groupId: listSelected[0].itemFlight.groupId,
              airlineCode: listSelected[0].itemFlight.airline,
              fareSourceCode:
              listSelected[0].pricedItinerary.airItineraryPricingInfo
                .fareSourceCode,
              supplierCode: listSelected[0].itemFlight.airSupplier,
              searchId: listSelected[0].searchId
            });
          }
        }
      })
    );
    this.subscription.add(
      this.flightSelectedDone$.subscribe(done => {
        this.flightSelectedDone = done;
        if (done) {
          this.adultTicketPrice = this._comboSelectedService.flightListSelectedArray.reduce(
            (arr, cur) => {
              return (
                arr +
                cur.pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerFare.baseFare.amount +
                (cur.pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerFare.comboMarkup
                  ? cur.pricedItinerary.airItineraryPricingInfo.adultFare
                      .passengerFare.comboMarkup.amount
                  : 0) +
                cur.pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerFare.serviceTax.amount
              );
            },
            0
          );
          const flightListPrice = this._comboSelectedService.flightListSelectedArray.map(
            flightSelected => flightSelected.pricedItinerary
          );
          this._comboSelectedService.setLowestPriceInFlight(flightListPrice);
          this._comboSelectedService.saveFlightLocalStorage();
        }
      })
    );

    this.subscription.add(
      this.allFlightSearch$.subscribe(data => {
        if (Object.keys(data).length > 0) {
          if (
            data.groupPricedItineraries &&
            data.groupPricedItineraries.length > 0
          ) {
            if (
              data.groupPricedItineraries[0].flightType ===
              GroupPricedItinerary.FlightTypeEnum.INTERNATIONAL
            ) {
              this.flightFilterFacade.changeFlightType(
                GroupPricedItinerary.FlightTypeEnum.INTERNATIONAL
              );
            }
          }
          this.flightFilterFacade.changeSearchID(data.searchId);
          this.lowestPriceFlightsFacade.loadAll({
            searchId: data.searchId,
            departureItinerary: null
          });
        }
      })
    );
    this.subscription.add(
      this.currentFilter$.subscribe((value: FlightFilterState) => {
        if (value.searchID) {
          this.availableFlightsFacade.loadAll({
            filter: {
              searchId: value.searchID,
              filter: {
                cabinClassOptions: value.cabinClassOptions,
                ticketPolicyOptions: value.ticketPolicyOptions,
                airlineOptions: value.airlineOptions,
                stopOptions: value.stopOptions,
                step: value.departureItinerary ? '2' : value.step,
                flightType: value.flightType,
                departureDateTimeOptions: value.departureDateTimeOptions,
                arrivalDateTimeReturnOptions:
                  value.arrivalDateTimeReturnOptions,
                arrivalDateTimeOptions: value.arrivalDateTimeOptions,
                priceItineraryId: value.priceItineraryId,
                minPrice: value.minPrice,
                loadMore: value.loadMore,
                groupId: value.groupId,
                departureDateTimeReturnOptions:
                  value.departureDateTimeReturnOptions
              },
              departureItinerary: value.departureItinerary
            },
            includeEquivfare: false,
            page: value.page,
            size: value.size,
            sort: DEFAULT_SORT_COMBO
          });
        }
      })
    );
  }
  researchWhenReturnEmpty() {
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: 'combovj.search-result.dialog.not-found-description',
        heading: 'combovj.search-result.dialog.not-found-title',
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(action => {
      this.router.navigate(['/combo-du-lich']);
    });
  }

  private _listenLowFareSearch2(
    departureItinerary?: AirItineraryInfo,
    reset?: boolean
  ) {
    this.subscription.add(
      this.allFlightSearch$.pipe(take(1)).subscribe(data => {
        if (
          Object.keys(data).length > 0 &&
          data.departureSearchId &&
          data.returnSearchId
        ) {
          this.flightFilterFacade.selectDeparture({
            searchID: reset ? data.searchId : data.returnSearchId,
            departureItinerary: departureItinerary
          });
          this.lowestPriceFlightsFacade.loadAll({
            searchId: data.returnSearchId,
            departureItinerary: departureItinerary
          });
        } else if (
          (Object.keys(data).length > 0 &&
            !data.returnSearchId &&
            this.searchParams.roundType === 'ROUNDTRIP') ||
          (Object.keys(data).length > 0 && !data.departureSearchId)
        ) {
          this.flightNotFound = true;
        }
      })
    );
  }

  private _listenLowFareSearchInter(
    departureItinerary?: AirItineraryInfo,
    reset?: boolean
  ) {
    this.subscription.add(
      this.allFlightSearch$.pipe(take(1)).subscribe(data => {
        if (
          Object.keys(data).length > 0 &&
          data.departureSearchId
        ) {
          this.availableFlightsFacade.loadInternationalFlights({
            id: departureItinerary.groupId,
            airSearchId: {
              searchId: departureItinerary.searchId,
              filter: {
                arrivalDateTimeOptions: [],
                departureDateTimeOptions: [],
                flightType: 'INTERNATIONAL',
                groupId: departureItinerary.groupId,
                step: '2',
                stopOptions: [],
                ticketPolicyOptions: []
              },
              departureItinerary: {
                fareSourceCode: departureItinerary.fareSourceCode
              }
            },
            sort: ['stopOptions,asc']
          });
        }
      })
    );
  }

  openBottomSheet(): void {
    this._bottomSheet.open(FilterPanelMobileComponent, {
      data: this.currentFilter ? this.currentFilter : null
    });
  }

  pageChanged(event) {
    setTimeout(() => {
      this.configPagination.currentPage = event;
      if (event) this.filterAvailableFacade.changePageNumber(event - 1);
    });
  }

  addressClickViewMap(event) {
    this.searchParams.openMarker = event;
    this.router.navigate(['/combo/hotel/maps'], {
      queryParams: {
        params: this.params
      }
    });
  }

  changeTabSelect(index: number) {
    this.router.navigate(['/combo/hotel/maps'], {
      queryParams: {
        params: this.params
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.searchBestRatesFacade.searchResetAll();
    this.subService.unsubscribeSubject$.next();
    // this.filterAvailableFacade.resetFilter();
    // this.flightSearchFacade.resetAll();
    this.availableFlightsFacade.resetAll();
    this.lowestPriceFlightsFacade.resetAll();
    this.flightFilterFacade.resetFilter();
    // this._comboSelectedService.resetAirline();
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }

  onScrollSearch(pageNumber: number, totalPage: number, totalItems: number) {
    if((totalPage > pageNumber && pageNumber < 5) || (pageNumber == 1 && totalItems > 1)) {
      this.isLoading = true;
      this.filterAvailableFacade.changePageNumber(pageNumber);
    } else {
      this.isLoading = false;
    }
  }
  pageUpdate(event) {
    this.isLoading = true;
    this.filterAvailableFacade.changePageNumber(event);
  }

  ngAfterViewInit() {
    this.isFilter = false;
    this.cdr.detectChanges(); // Manually trigger change detection
  }
}
@Pipe({ name: 'round' })
export class RoundPipe {
  transform(input: any) {
    return parseFloat(input).toFixed(1);
  }
}
@Pipe({ name: 'percentPrice' })
export class PercentPrice {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}
@Pipe({ name: 'imageResize' })
export class ImageResize {
  transform(img: string, size: number) {
    return img.replace('s=1000x', 's='+size+'x');
  }
}

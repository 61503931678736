import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BASE_PATH } from './variables';
import { ReferralResourceService } from './api/referralResource.service';
import { environment } from '@env/environment';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.agentsrv },
    ReferralResourceService
  ],
})
export class AgentsrvModule {}

import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Btn} from "@gtd/components/dialog-message";
import {isPlatformBrowser} from "@angular/common";
import {DialogMessageBooking} from "../dialogMessageBooking";

@Component({
  selector: 'gtd-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessageBooking,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.parent.postMessage({ action: 'scrollToTop' }, '*');
    }
  }

  ngOnInit() {}

  onNoClick(btn: Btn, action?: boolean): void {
    if (action) {
      if (btn.btnLink) {
        this.router.navigate([btn.btnLink]);
      }
      if (btn.btnReload) {
        window.location.reload();
      }
    } else {
      this.dialogRef.close({ action: btn.action ? btn.action : '' });
    }
  }

  onCloseDialog() {
    this.dialogRef.close({ action: '' });
  }
}

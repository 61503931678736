export * from './accountResource.service';
import { AccountResourceService } from './accountResource.service';
export * from './additionalRequestResource.service';
import { AdditionalRequestResourceService } from './additionalRequestResource.service';
export * from './agencyFinancialResource.service';
import { AgencyFinancialResourceService } from './agencyFinancialResource.service';
export * from './agencyResource.service';
import { AgencyResourceService } from './agencyResource.service';
export * from './agentResource.service';
import { AgentResourceService } from './agentResource.service';
export * from './bookingSupplierResource.service';
import { BookingSupplierResourceService } from './bookingSupplierResource.service';
export * from './bookingTransactionResource.service';
import { BookingTransactionResourceService } from './bookingTransactionResource.service';
export * from './branchResource.service';
import { BranchResourceService } from './branchResource.service';
export * from './caResource.service';
import { CaResourceService } from './caResource.service';
export * from './cancelBookingResource.service';
import { CancelBookingResourceService } from './cancelBookingResource.service';
export * from './cannyTokenResource.service';
import { CannyTokenB2bResourceService } from './cannyTokenResource.service';
export * from './customerResource.service';
import { CustomerResourceService } from './customerResource.service';
export * from './dashboardResource.service';
import { DashboardResourceService } from './dashboardResource.service';
export * from './gatewayResource.service';
import { GatewayResourceService } from './gatewayResource.service';
export * from './haravanWebhookResource.service';
import { HaravanWebhookResourceService } from './haravanWebhookResource.service';
export * from './issueDocumentResource.service';
import { IssueDocumentResourceService } from './issueDocumentResource.service';
export * from './leaderBoardResource.service';
import { LeaderBoardResourceService } from './leaderBoardResource.service';
export * from './loginUserResource.service';
import { LoginUserResourceService } from './loginUserResource.service';
export * from './lookupResource.service';
import { LookupResourceService } from './lookupResource.service';
export * from './markupB2BResource.service';
import { MarkupB2BResourceService } from './markupB2BResource.service';
export * from './markupB2CResource.service';
import { MarkupB2CResourceService } from './markupB2CResource.service';
export * from './markupExceptionResource.service';
import { MarkupExceptionResourceService } from './markupExceptionResource.service';
export * from './migrateDataResource.service';
import { MigrateDataResourceService } from './migrateDataResource.service';
export * from './offlineBookingResource.service';
import { OfflineBookingResourceService } from './offlineBookingResource.service';
export * from './paymentResources.service';
import { PaymentResourcesService } from './paymentResources.service';
export * from './pricingScheduleResource.service';
import { PricingScheduleResourceService } from './pricingScheduleResource.service';
export * from './productResource.service';
import { ProductResourceService } from './productResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './promotionResource.service';
import { PromotionResourceService } from './promotionResource.service';
export * from './refundFailedBookingResource.service';
import { RefundFailedBookingResourceService } from './refundFailedBookingResource.service';
export * from './topupResource.service';
import { TopupResourceService } from './topupResource.service';
export * from './userJwtController.service';
import { UserJwtControllerService } from './userJwtController.service';
export * from './userProfileResource.service';
import { UserProfileResourceService } from './userProfileResource.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export * from './utilResource.service';
import { UtilResourceService } from './utilResource.service';
export * from './versionResource.service';
import { VersionResourceService } from './versionResource.service';
export * from './voidBookingResource.service';
import { VoidBookingResourceService } from './voidBookingResource.service';
export * from './approvalProcessResource.service';
import { ApprovalProcessResourceService } from './approvalProcessResource.service';
import { ReportResourceService } from './reportResource.service';
export * from './reportResource.service';
import { ApprovalBookingRequestResourceService } from './approvalBookingRequestResource.service';
export * from './approvalBookingRequestResource.service';
import { TravelPolicyResourceService } from './travelPolicyResource.service';
export * from './travelPolicyResource.service';

import { UserOtpStatusResourceService } from './userOtpStatusResource.service';
export * from './userOtpStatusResource.service';

export const APIS = [
  AccountResourceService,
  AdditionalRequestResourceService,
  AgencyFinancialResourceService,
  AgencyResourceService,
  AgentResourceService,
  BookingSupplierResourceService,
  BookingTransactionResourceService,
  BranchResourceService,
  CaResourceService,
  CancelBookingResourceService,
  CannyTokenB2bResourceService,
  CustomerResourceService,
  DashboardResourceService,
  GatewayResourceService,
  HaravanWebhookResourceService,
  IssueDocumentResourceService,
  LeaderBoardResourceService,
  LoginUserResourceService,
  LookupResourceService,
  MarkupB2BResourceService,
  MarkupB2CResourceService,
  MarkupExceptionResourceService,
  MigrateDataResourceService,
  OfflineBookingResourceService,
  PaymentResourcesService,
  PricingScheduleResourceService,
  ProductResourceService,
  ProfileInfoResourceService,
  PromotionResourceService,
  RefundFailedBookingResourceService,
  TopupResourceService,
  UserJwtControllerService,
  UserProfileResourceService,
  UserResourceService,
  UtilResourceService,
  VersionResourceService,
  VoidBookingResourceService,
  ApprovalProcessResourceService,
  ReportResourceService,
  ApprovalBookingRequestResourceService,
  TravelPolicyResourceService,
  UserOtpStatusResourceService
];

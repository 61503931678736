/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserOtpStatusRequest {
    email?: string;
    gtdUserType?: UserOtpStatusRequest.GtdUserTypeEnum;
    isAnonymous?: boolean;
    isOtpEnabled?: boolean;
    isPasswordCorrected?: boolean;
    otpType?: UserOtpStatusRequest.OtpTypeEnum;
    password?: string;
    phoneNumber?: string;
}
export namespace UserOtpStatusRequest {
    export type GtdUserTypeEnum = 'B2B' | 'B2C' | 'MIS';
    export const GtdUserTypeEnum = {
        B2B: 'B2B' as GtdUserTypeEnum,
        B2C: 'B2C' as GtdUserTypeEnum,
        MIS: 'MIS' as GtdUserTypeEnum
    };
    export type OtpTypeEnum = 'EMAIL' | 'PHONE';
    export const OtpTypeEnum = {
        EMAIL: 'EMAIL' as OtpTypeEnum,
        PHONE: 'PHONE' as OtpTypeEnum
    };
}

import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountResourceService } from './api/accountResource.service';
import { AdditionalRequestResourceService } from './api/additionalRequestResource.service';
import { AgencyFinancialResourceService } from './api/agencyFinancialResource.service';
import { AgencyResourceService } from './api/agencyResource.service';
import { AgentResourceService } from './api/agentResource.service';
import { BookingSupplierResourceService } from './api/bookingSupplierResource.service';
import { BookingTransactionResourceService } from './api/bookingTransactionResource.service';
import { BranchResourceService } from './api/branchResource.service';
import { CaResourceService } from './api/caResource.service';
import { CancelBookingResourceService } from './api/cancelBookingResource.service';
import { CustomerResourceService } from './api/customerResource.service';
import { DashboardResourceService } from './api/dashboardResource.service';
import { GatewayResourceService } from './api/gatewayResource.service';
import { HaravanWebhookResourceService } from './api/haravanWebhookResource.service';
import { IssueDocumentResourceService } from './api/issueDocumentResource.service';
import { LeaderBoardResourceService } from './api/leaderBoardResource.service';
import { LoginUserResourceService } from './api/loginUserResource.service';
import { LookupResourceService } from './api/lookupResource.service';
import { MarkupB2BResourceService } from './api/markupB2BResource.service';
import { MarkupB2CResourceService } from './api/markupB2CResource.service';
import { MarkupExceptionResourceService } from './api/markupExceptionResource.service';
import { MigrateDataResourceService } from './api/migrateDataResource.service';
import { OfflineBookingResourceService } from './api/offlineBookingResource.service';
import { PaymentResourcesService } from './api/paymentResources.service';
import { PricingScheduleResourceService } from './api/pricingScheduleResource.service';
import { ProductResourceService } from './api/productResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { PromotionResourceService } from './api/promotionResource.service';
import { RefundFailedBookingResourceService } from './api/refundFailedBookingResource.service';
import { TopupResourceService } from './api/topupResource.service';
import { UserJwtControllerService } from './api/userJwtController.service';
import { UserProfileResourceService } from './api/userProfileResource.service';
import { UserResourceService } from './api/userResource.service';
import { UtilResourceService } from './api/utilResource.service';
import { VersionResourceService } from './api/versionResource.service';
import { VoidBookingResourceService } from './api/voidBookingResource.service';
import { CommonModule } from '@angular/common';
import { ApprovalProcessResourceService } from './api/approvalProcessResource.service';
import { BASE_PATH } from './variables';
import { environment } from '@env/environment';
import { ApprovalBookingRequestResourceService } from './api/approvalBookingRequestResource.service';
import { TravelPolicyResourceService } from './api/travelPolicyResource.service';
import {UserOtpStatusResourceService} from "./api/userOtpStatusResource.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2bClient },
    AccountResourceService,
    AdditionalRequestResourceService,
    AgencyFinancialResourceService,
    AgencyResourceService,
    AgentResourceService,
    BookingSupplierResourceService,
    BookingTransactionResourceService,
    BranchResourceService,
    CaResourceService,
    CancelBookingResourceService,
    CustomerResourceService,
    DashboardResourceService,
    GatewayResourceService,
    HaravanWebhookResourceService,
    IssueDocumentResourceService,
    LeaderBoardResourceService,
    LoginUserResourceService,
    LookupResourceService,
    MarkupB2BResourceService,
    MarkupB2CResourceService,
    MarkupExceptionResourceService,
    MigrateDataResourceService,
    OfflineBookingResourceService,
    PaymentResourcesService,
    PricingScheduleResourceService,
    ProductResourceService,
    ProfileInfoResourceService,
    PromotionResourceService,
    RefundFailedBookingResourceService,
    TopupResourceService,
    UserJwtControllerService,
    UserProfileResourceService,
    UserResourceService,
    UtilResourceService,
    VersionResourceService,
    VoidBookingResourceService,
    ApprovalProcessResourceService,
    AgentResourceService,
    ApprovalBookingRequestResourceService,
    TravelPolicyResourceService,
    UserOtpStatusResourceService
  ]
})
export class B2bClientModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders {
    return {
      ngModule: B2bClientModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }
}

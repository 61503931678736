// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'vi',
  supportedLanguages: ['en', 'vi'],
  appName: 'B2C',
  googleAnalyticsId: '',
  api: {
    b2cClient: 'https://uat-api.gotadi.com/uat-b2c',
    b2bClient: 'https://uat-b2b.gotadi.com',
    customerService: 'https://uat-api.gotadi.com/uat-b2c/customersrv',
    metaService: 'https://uat-api.gotadi.com/uat-b2c/metasrv',
    bookingService: 'https://uat-api.gotadi.com/uat-b2c/bookingsrv',
    covidNotifications: 'https://uat-api.gotadi.com/uat-b2c/ticketsrv/',
    inventoryService:
      'https://uat-api.gotadi.com/b2c-web/inventorysrv',
    agentsrv: 'https://uat-api.gotadi.com/uat-b2c/agentsrv',
    utilitysrv: 'https://uat-api.gotadi.com/uat-b2c/utilitysrv',
    metasrv: 'https://uat-api.gotadi.com/uat-b2c/metasrv',
    pricingsrv: 'https://uat-api.gotadi.com/uat-b2c/pricingsrv'
  },
  web: {
    version: 'Newface 1.0.2',
    phone: ['1900 9002', '(028) 62 857 857']
  },
  socialConfig: {
    facebookAppId: '840002496142196',
    fanpageUrl: '',
    skypeChat: 'retkpop',
    googleOAuthClientId:
      '51263142933-1pf3mvhhbrahsm4ha3e2t722co3ao5s3.apps.googleusercontent.com'
  },
  cdn: {
    cdnGotadi: 'https://booking.gotadi.com/',
    gtdBanner: 'https://www2.gotadi.com/',
    logoAirline:
      'https://booking.gotadi.com/Booking/AirBooking/images/AirLogos/'
  },
  downloadApp: {
    ios:
      'https://itunes.apple.com/us/app/gotadi-flight-booking/id1234937358?mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.vn.gotadi.mobileapp'
  },
  hotelTempUrl: 'https://www.gotadi.com/#/hotel-search?params='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
